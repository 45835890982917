.blog {
  padding: 50px 0;
}

.blog h1 {
  text-align: center;
  color: #a0cd4e;
  margin-bottom: 40px;
  /* font-weight: 600; */
}

.blog .card {
  position: relative;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 0 18px 0 hsl(81deg 56% 55%);
  text-decoration: none;
  transition: 0.2s;
  overflow: hidden;
  border: none;
}

.blog .card .main-img {
  height: 450px;
  object-fit: fill;
}

@media (max-width: 600px) {
  .blog .card .main-img {
    height: 350px;
  }
}

.blog .card .blogCardText {
  padding: 20px;
}

.blog .card .blogCardText h3 {
  font-weight: 600;
  font-size: 25px;
}

.blog .card .blogCardText h6 {
  margin-top: 20px;
  color: #a4a3a4;
}

.blog .card .bage-tag {
  position: absolute;
  background-image: linear-gradient(to right, #a0cd4e, black);
  padding: 6px 10px;
  border-radius: 5px;
  left: 20px;
  top: 20px;
  color: black;
  font-weight: 600;
}

.blog .small-card {
  padding: 10px 20px;
  margin-bottom: 30px;
}

.blog .small-card .bage-warning {
  background-color: black;
  padding: 5px 10px;
  color: black;
  font-weight: 600;
  width: fit-content;
  border-radius: 5px;
  margin-bottom: 10px;
}

.blog .small-card h5 {
  font-size: 17px;
  color: black;
  margin-top: 10px;
  font-weight: 700;
}

.blog a {
  list-style: none;
  text-decoration: none;
}

.blog .catagiory {
  display: flex;
  align-items: center;
}
.blog .catagiory .nav-pills {
  padding: 5px;
  border-radius: 5px;
  background: black;
  margin-right: 10%;
}
.blog .catagiory .nav-pills .nav-link {
  color: black;
}
.blog .catagiory .nav-pills .nav-link.active,
.blog .catagiory .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
  background-image: linear-gradient(to right, #a0cd4e, #09bbde);
}

.blog .catagiory .selct-drop {
  min-width: 155px;
}
.blog .search-field {
  border: 1px solid #293eb5;
  border-radius: 10px;
  background-color: #172367;
  padding: 5px;
}
.blog .search-field .search-btn {
  color: #fff;
  position: absolute;
  right: 30px;
  top: 9px;
  border: none;
  background: none;
  padding: 2px 10px;
  z-index: 999;
  cursor: pointer;
}
.blog .search-field input {
  background: none;
  border: none;
  color: black;
}
.blog .form-control:focus {
  outline: none;
  box-shadow: none;
}
.blog .card-info {
  padding: 10px;
}
.blog .card-info h2 {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.blog .card-info h6 {
  color: black;
}
.blog .card {
  margin-bottom: 30px;
}
.blog .card .sm-img {
  /* height: 250px; */
  object-fit: fill;
}
.leran-now {
  margin-top: 50px;
}
.leran-now .big-card {
  background-image: linear-gradient(to right, #a0cd4e, black);
  padding: 30px;
  border-radius: 10px;
}
.leran-now .big-card h3 {
  font-weight: 700;
  color: black;
  font-size: 25px;
  margin-bottom: 20px;
}
.leran-now .big-card p {
  color: black;
}
.blog-details {
  padding: 50px 0;
}
.blog-details h1 {
  /* font-weight: 600; */
  color: black;
  text-align: center;
  /* max-width: 600px; */
}
.blog-details h6 {
  color: black;
}
.blog-details .bage-theme {
  width: fit-content;
  background-image: linear-gradient(to right, #a0cd4e, black);
  padding: 6px 10px;
  border-radius: 5px;
  color: black;
  font-weight: 600;
}
.blog-details .details-img {
  width: 100%;
  height: 550px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 20px 0;
  object-fit: fill;
}
.blog-details .social-icons ul {
  list-style: none;
  padding: 0;
}
.blog-details .social-icons ul li a {
  display: block;
  width: 42px;
  height: 42px;
  background-image: linear-gradient(to right, #a0cd4e, black);
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-bottom: 12px;
  transition: 0.2s ease;
  color: black !important;
  text-align: center;
  padding-top: 8px;
  font-size: 20px;
  cursor: pointer;
}

.blog-details .info-details img {
  height: 550px;
  width: 100%;
  object-fit: fill;
  padding: 20px 0px;
}
.blog-details .info-details p {
  color: black;
  font-size: 17px;
  margin-top: 10px;
}
.blog-details .info-details h1 {
  color: black;
  /* font-weight: 600; */
  margin: 10px 0;
}
.blog-details .info-details h2 {
  color: black;
  /* font-weight: 600; */
  margin: 10px 0;
}
.blog-details .info-details h3 {
  color: black;
  font-weight: 600;
  margin: 10px 0;
}
.blog-details .info-details h4 {
  color: black;
  /* font-weight: 600; */
  margin: 10px 0;
}
.blog-details .info-details h5 {
  color: black;
  /* font-weight: 600; */
  margin: 10px 0;
}
.blog-details .info-details a {
  color: black;
  text-decoration: underline;
  font-size: 17px;
}
.blog-details .info-details h1 a {
  font-size: 2.5rem;
}
.blog-details .info-details h2 a {
  font-size: 2rem;
}
.blog-details .info-details ul,
.blog-details .info-details ol {
  list-style: none;
  margin-bottom: 20px;
}
.blog-details .info-details ul li,
.blog-details .info-details ol li {
  position: relative;
  padding-left: 20px;
  color: black;
  margin-bottom: 8px;
}
.blog-details .info-details ul li:before,
.blog-details .info-details ol li:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-image: linear-gradient(to right, #a0cd4e, black);
  border-radius: 100%;
  left: 0;
  top: 5px;
}
@media (max-width: 770px) {
  .social-icons ul {
    display: flex;
  }
  .social-icons ul li {
    margin-right: 10px;
  }
  .blog-details .details-img {
    height: 350px;
  }
  .blog-details img {
    height: 350px;
  }
}

.blog-details video {
  width: 100%;
}
footer h5 a{color:#fff;}
.sidebar a{
  color: #000;
  font-size: 14px;
  margin-bottom: 15px;
  display: block;
}
.sidebar a:hover{
  text-decoration:underline;
}
.sidebar p{
  font-size: 14px;
  padding-top: 10px;
}

/* Background overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

/* Popup box */
.popup-box {
  background: white;
  border-radius: 10px;
  width:50%;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Close button */
.close-btn {
  background: red;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.close-btn:hover {
  background: darkred;
}
.pop-left{width: 44%;float: left;}
.pop-right{    width: 56%;
  float: left;
  padding: 40px 60px 40px 40px;}
.pop-right h1{
  font-size: 35px;
  font-weight: bold;
  color:#000;
  text-align: left;
  line-height: 100%;
}
.pop-right h3{
  font-size: 20px;
  font-weight: 400;
  color:#000;
  text-align: left;
  line-height: 100%;
}
.pop-right ul{margin-left: -20px;margin-bottom: 25px;}
.pop-right p, .pop-right li{
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  text-align: left;
}
.pop-right .inpt{
  background: #D9D9D9;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 43px;
  font-size: 12px;
  padding-left: 19px;
  margin-bottom: 15px;
}
.pop-right .close-btn{
  background: #76CE28;
  border: none;
  color: #000;
  border-radius: 10px;
  font-weight: 700;
  width: 100%;
  height: 43px;
  font-size: 15px;
  margin-top: 0px;
}
.pop-right .error-border{border: 1px solid red;}
@media (max-width: 770px) {
  .popup-box {width:90%;}
}